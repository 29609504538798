import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import LanguagePicker from './LanguagePicker';
import linkArray from './data/links.json';
import linkText from './data/linkText.json';

const components = {
	'Home': Home,
	'About': About,
	'Projects': Projects
}

const RouteHandler = ({appData, setAppData}) => {
	let {languageCode} = appData;
	return (
		<Router>
			<nav className="navigation">
				<Links languageCode={languageCode} />
				<LanguagePicker setAppData={setAppData} appData={appData} />
			</nav>
			<Switch>
				<Routes languageCode={languageCode} />
			</Switch>
		</Router>
	);
}

const Routes = ({languageCode}) => {
	return (
		linkArray.map(({href, component}, index) => (
			component in components ?
				<Route exact path={href} key={index}>
					{React.createElement(components[component], {languageCode})}
				</Route>
				: ''
		))
	);
}

const Links = ({languageCode}) => {
	return (
		<ul>
			{
				linkArray.map(({href, code, component, active}, index) => (
					component in components && active ?
						<li className="nav-item" key={index}>
							<Link to={href}>{linkText[code][languageCode]}</Link>
						</li>
						: ''
				))
			}
		</ul>
	);
}

export default RouteHandler;