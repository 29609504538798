import React from 'react';
import textData from '../data/pages/Home.json';

const Home = ({ languageCode, }) => {
	return (
		<div className="main-content">
			<h2>
				{textData[languageCode].title}
			</h2>
			<p>
				{textData[languageCode].text}
			</p>
		</div>
	);
}

export default Home;