import React, { useEffect, useState } from 'react';
import './App.css';
import RouteHandler from './RouteHandler.js';

const App = () => {
	const [appData, setAppData] = useState(initAppData());
	useEffect(() => {
		setCookie('language', appData.languageCode);
	}, [appData.languageCode]);

	return (
		<RouteHandler setAppData={setAppData} appData={appData} />
	);
}

const initAppData = () => {
	return {
		languageCode: getCookie('language', 'sv'),
		setCookie: setCookie,
		getCookie: setCookie,
		updateLanguage: updateLanguage
	};
}

const updateLanguage = (data, key, languageCode) => {
	return data[languageCode][key];
}

const setCookie = (name, value, expiryDays = 0) => {
	let dateString = '';
	if (expiryDays) {
		let date = new Date();
		date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
		dateString = ";expires=" + date.toUTCString();
	}
	document.cookie = `${name}=${value}${dateString};path=/`
}

const getCookie = (name, defaultValue = '') => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(';').shift();
	}
	else {
		return defaultValue;
	}
}

export default App;
