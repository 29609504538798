import React from 'react';
import textData from '../data/pages/Projects.json';

const Projects = ({ languageCode }) => {
	let key = 0;
	return (
		<div className="main-content">
			{
				textData[languageCode].map((obj) => {
					return (
						<div key={key++} className="project-container">
							<h2 key={key++}>{obj.title}</h2>
							{
								obj.text.map((text) => {
									return (
										<p key={key++}>
											{text}
										</p>
									)
								})
							}
						</div>
					)
				})
			}
		</div>
	)
}

export default Projects;