import React, { useEffect, useState } from 'react';
import Flag from 'react-country-flag';
import countries from './data/availableCountries.json';

const LanguagePicker = ({ setAppData, appData }) => {
	let windowWidthMin = 650;
	let { languageCode } = appData;

	let [currentCountry, setCurrentCountry] = useState(getCountry(languageCode, countries));

	let [flagHolderClasses, setFlagHolderClasses] = useState(['flag-holder']);
	let [flagHolder, setFlagHolder] = useState('flag-holder');
	const clickEvent = (value) => {
		if (languageCode === value && window.innerWidth <= windowWidthMin) {
			setFlagHolderClasses(toggleArrayValue('flag-holder--open', flagHolderClasses));
		}
		else {
			setFlagHolderClasses(removeArrayValue('flag-holder--open', flagHolderClasses));
			setAppData({ ...appData, languageCode: value });
			setCurrentCountry(getCountry(value, countries));
		}
	}

	useEffect(() => {
		setFlagHolder(flagHolderClasses.join(' '));
	}, [flagHolderClasses]);

	return (
		<div className={flagHolder}>
			<Flag className="country-flag"
				countryCode={currentCountry.iso}
				svg

				style={{}}

				aria-label={currentCountry.title[languageCode]}
				onClick={() => clickEvent(languageCode)}
			/>
			{
				countries.map(({ iso, language, title, active }, index) => (
					active && language !== currentCountry.language ? 
					<Flag key={index}
						className="country-flag"
						countryCode={iso}
						svg

						style={{}}

						aria-label={title[languageCode]}
						onClick={() => clickEvent(language)}
					/> : ''
				))
			}
		</div>
	)
}

const getCountry = (languageCode, countries) => {
	return countries.find(country => {
		return country.language === languageCode;
	})
}

const removeArrayValue = (value, array) => {
	let arr = [...array];
	let idx = arr.indexOf(value);
	if (idx !== -1) {
		arr.splice(idx, 1);
	}
	return arr;
}

const toggleArrayValue = (value, array) => {
	let arr = [...array];
	let idx = arr.indexOf(value);
	if (idx === -1) {
		arr.push(value);
	}
	else {
		arr.splice(idx, 1);
	}
	return arr;
}

export default LanguagePicker;