import React from 'react';
import textData from '../data/pages/About.json';
import jobTitleData from '../data/jobTitles.json';

const About = ({ languageCode }) => {
	const cellName = 'about-table--cell';
	const rowName = 'about-table--row';
	let index = 0;
	return (
		<div className="main-content">
			<h2 key={index++}>{textData.title[languageCode]}</h2>
			<p key={index++}>{textData.text[languageCode]}</p>
			<div className="about-table">
				{
					textData.people.map(({ jobTitles, name, email }) => {
						return (
							<div className={cellName} key={index++}>
								<div className={rowName} key={index++}>
									<p key={index++}>
										{textData.descriptors.jobTitle[languageCode]}
									</p>
									<p key={index++}>
										{renderJobTitles(jobTitles, languageCode)}
									</p>
								</div>
								<div className={rowName} key={index++}>
									<p key={index++}>
										{textData.descriptors.name[languageCode]}
									</p>
									<p key={index++}>
										{name}
									</p>
								</div>
								<div className={rowName} key={index++}>
									<p key={index++}>
										{textData.descriptors.email[languageCode]}
									</p>
									<p key={index++}>
										<a href={`mailto:${email}`} key={index++}>
											{email}
										</a>
									</p>
								</div>
							</div>
						);
					})
				}
			</div>
		</div>
	)
}

const renderJobTitles = (jobTitles, languageCode) => {
	const data = [];
	jobTitles.forEach(jobTitle => {
		data.push(jobTitleData[jobTitle][languageCode]);
	});
	return data.join(', ');
}

export default About;